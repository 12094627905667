import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { useQuery, gql } from '@apollo/client';

const GET_LINKS = gql`
  query ExampleQuery {
    getLinks {
      title
    }
  }
`;

const Test = () => {
  const { loading, data, error } = useQuery(GET_LINKS);
  console.log(loading,data,error);
  if ( loading ) {
    return (
      <>loading...</>
    )
  }

  return (
    <>
      Test page
    </>
  )
}

export default Test;
